html {
  font-size: $base-font-size + 0px;
  line-height: $base-line-height;
  height: 100%;
  -webkit-tap-highlight-color: transparent;
  @include set-font('body');
}

body {
  -webkit-font-smoothing: antialiased;
  font-variant-numeric: lining-nums;
  -moz-font-feature-settings: 'lnum';
  -webkit-font-feature-settings: 'lnum';
  font-feature-settings: 'lnum';
  font-family: 'domaine-web', sans-serif;
  font-weight: 400;
  color: $black;
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  // opacity: .0000001;
  // .no-js &,
  // .initialised & {
  //   opacity: 1;
  // }
  // @include tablet-plus {
  //   &.state-expanded-gallery-present {
  //     overflow-y: hidden;
  //   }
  // }

  // > * {
  //   opacity: 0;
  //   .initialised &,
  //   .no-js & {
  //     opacity: 1;
  //   }
  // }
}

#header {
  position: relative;
  z-index: 100;
  @include set-font('toolbar');
  &::after {
    // buffer for fixed .inside (must match height)
    content: '';
    display: block;
    height: $toolbar-height;
    position: relative;
  }
  .primary {
    a {
      @include standard-text-link;
    }
  }
  @include phone {
    // @#header
    .box { // burger / navigation-trigger
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 2;
      background: $white;
      border-bottom: 1px solid $line;
      text-align: right;
      .page-title {
        position: absolute;
        top: inset();
        left: 50%;
        transform: translateX(-50%);
        pointer-events: none;
      }
      .burger {
        margin-right: calc(#{$rhythm} - 7px);
      }
    }
    .primary {
      position: fixed;
      top: $toolbar-height;
      left: 0;
      right: 0;
      bottom: -1px;
      z-index: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      background: $white;
      transform: translateY(calc(-100.5% - #{$toolbar-height}));
      pointer-events: none;
      transition: 1200ms transform cubic-bezier(.645, .045, .355, 1);
      border-bottom: 1px solid $line;
      .navigation-visible & {
        transform: translateY(0%);
        pointer-events: all;
      }
      ul {
        display: flex;
        flex-direction: column;
        margin-bottom: $rhythm * 2;
        margin-top: $rhythm;
        li {
          margin-bottom: $rhythm;
          order: 2;
          &.reservation {
            order: 1;
            margin-bottom: $rhythm * 3;
          }
          &.mobile-link {
            order: 1;
            @include tablet-plus {
              display: none;
            }
          }
        }
      }
      > div {
        margin-bottom: $rhythm * 2;
        > * {
          display: block;
          margin-bottom: $rhythm;
        }
        &.contact {
          order: 2;
          margin-bottom: 0;
        }
      }
    }
  }
  @include tablet-plus {
    // @#header
    .inside {
      transform: translateZ(0);
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 101;
      background: $white;
      border-bottom: 1px solid $line;
      .restrict {
        @include grid-container;
      }
    }
    .primary {
       ul {
         display: flex;
         flex-direction: row;
         justify-content: flex-start;
         li {
           a {
             display: block;
           }
           &:not(.end) {
             a {
               padding: $rhythm 2em;
             }
           }
           &.end {
             margin-left: auto;
             a {
               padding: $rhythm 2em;
             }
           }
           &.mobile-link {
             display: none;
           }
         }
       }
    }
  }
}


#content {
  position: relative;
  z-index: 1;
  > article {
    @include grid-container;
    @include fluid-plus {
      // @#content > article
      border-left: 1px solid $line;
      border-right: 1px solid $line;
    }
  }
}

#footer {
  position: relative;
  z-index: 99;
  a {
    @include standard-text-link;
  }
  @include tablet-plus {
    // @#footer
    @include set-font('toolbar');
    &::after {
      // buffer for fixed .inside (must match height)
      content: '';
      display: block;
      height: $toolbar-height;
    }
    .inside {
      transform: translateZ(0); // hardware accelerated
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      background: $white;
      border-top: 1px solid $line;
      .restrict {
        @include grid-container;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0 2em;
        > * {
          padding: $rhythm 0 $rhythm;
          min-width: 33.33%;
          &:nth-child(2) {
            text-align: center;
          }
          &:nth-child(3) {
            text-align: right;
          }
        }
      }
    }
    .contact {
      white-space: nowrap;
      > * {
        display: inline-block;
        margin-right: 2em;
      }
    }
    .social {
      a {
        display: inline-block;
        margin-left: 2em;
      }
    }
  }
}

.home-page {
  @include phone {
    // @.home-page
    #header {
      .box { // burger / navigation-trigger
        transition: 300ms background-color, 300ms border-bottom-color;
        .page-title {
          transition: 300ms opacity;
        }
      }
    }
    &:not(.scroll-display-toolbar) {
      #header {
        .box { // burger / navigation-trigger
          background-color: transparent;
          border-bottom-color: transparent;
          .page-title {
            opacity: 0;
          }
        }
      }
    }
  }
}

.home-content {
  background: $white;
  .tip-top {
    .brand {
      text-align: center;
    }
    .feature {
      position: relative;
      .image {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }
    @include phone {
      // @.tip.top
      .brand {
        margin-top: $rhythm;
        margin-bottom: $rhythm;
        img {
          max-width: 340px;
          height: auto;
          width: 70%;
        }
      }
      .feature {
        position: relative;
        z-index: 1;
        @include height-minus-toolbars(100, #{$toolbar-height / 2});
        margin-bottom: $rhythm;
      }
    }
    @include tablet-plus {
      // @.tip.top
      @include vertical-line(after);
      .inside {
        flex-direction: row;
        align-items: stretch;
        display: flex;
        > * {
          @include height-minus-toolbars;
          width: 50%;
        }
      }
      .brand {
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          max-width: 340px;
          height: auto;
          width: 80%;
        }
      }
      .feature {
        .image {
          top: inset();
          bottom: inset();
        }
      }
    }
    @include fluid-plus {
      // @.tip.top
      .feature {
        .image {
          right: inset();
        }
      }
    }
  }

  .introduction,
  .app,
  .stay {
    h1,
    h2 {
      font-weight: 700;
    }
    a {
      @include hover-underline;
    }
    @include phone {
      // @.introduction,
      //  .stay
      $title-font-size: 46;
      position: relative;
      .inside {
        @include vertical-line(after);
        padding-top: $rhythm * 6;
      }
      figure {
        @include vertical-line(after);
        padding-bottom: $rhythm;
      }
      h1 {
        @include font($title-font-size, .95, -.0077);
      }
      .columns {
        padding-top: inset();
      }
      .column {
        width: 90%;
        padding: $rhythm $rhythm $rhythm 0;
        &:last-child {
          padding-bottom: $rhythm * 5;
        }
      }
    }
    @include tablet-plus {
      // @.introduction
      //  .stay
      .inside {
        padding-bottom: 1px; // force internal margin
      }
      h1 {
        @include font(94, .9, -.0077);
      }
      .columns {
        padding-top: inset($multiple: 2);
      }
      .column {
        max-width: 21.5em;
        display: inline-block;
        vertical-align: top;
        margin-right: inset();
        margin-bottom: inset();
        padding-left: inset();
      }
      figure {
        @include vertical-line(before);
        @include vertical-line(after);
        padding-top: inset();
        padding-bottom: inset();
      }
    }
    @include fluid-plus {
      // @.introduction
      //  .stay
      figure {
        padding-left: inset();
        padding-right: inset();
      }
    }
  }
  .introduction {
    h1 {
      padding-bottom: 1em * .5;
      @include vertical-line(after);
      margin-bottom: 0;
    }
    @include phone {
      // @.introduction
      .inside {
        @include vertical-line(before);
        margin-bottom: $rhythm;
      }
    }
    @include tablet {
      // @.introduction
      .columns {
        @include prefix(8);
      }
    }
    @include tablet-plus {
      // @.introduction
      .inside {
        padding-top: $rhythm * 10;
      }
      .columns {
        @include prefix(12);
        border-left: 1px solid $line;
      }
      .column {
        width: calc(50% - #{inset()});
      }
    }
    @include fluid-plus {
      // @.introduction
      h1 {
        span {
          display: block;
          padding-left: inset();
          padding-right: inset();
        }
      }
    }
  }
  .app,
  .stay {
    @include tablet {
      // @.stay
      .column {
        width: calc(80% - #{inset()});
      }
    }
    @include tablet-plus {
      width: 50%;
      display: inline-block;
    }
    @include desktop-plus {
      // @.stay
      .column {
        width: calc(50% - #{inset()});
      }
    }
  }
  .app {
    @include tablet-plus {
      border-right: 1px solid $line;
      float: left;
    }
    h2 {
      @include font(40, 1, -.0095);
      max-width: 9em;
      padding-top: $rhythm * 3;
      @include tablet-plus {
        padding-top: $rhythm * 10;
      }
      span {
        display: block;
        padding-left: inset();
        @include tablet-minus {
          padding-left: 0;
        }
      }
    }
    .inside, .columns {
      @include phone {
        padding-top: 0;
      }
    }
    .columns {
      border-left: 0;
      .column {
        @include phone {
          width: 100%;
          padding-right: 0;
        }
        @include tablet {
          padding-left: 0;
        }
        .app-text {
          @include phone {
            max-width: 235px;
            margin-bottom: 2em;
          }
        }
        .mobile-download {
          @include font(14);
          color: $gray;
          margin-bottom: 3em;
          display: inline-block;
          border-bottom: 0;
          @include tablet-plus {
            display: none;
          }
        }
        img {
          display: block;
        }
        .qr-code {
          max-width: 80px;
          margin-bottom: 4em;
          @include phone {
            display: none;
          }
        }
        .iphone {
          max-width: 300px;
          margin-left: -3em;
        }
      }
    }
  }
  .stay {
    h1 {
      margin-bottom: 0;
    }
    @include phone {
      h1 {
        padding-bottom: 1em * .5;
        @include vertical-line(after);
      }
    }
    @include tablet-plus {
      // @.stay
      h1 {
        margin-bottom: 0;
        padding-top: $rhythm * 10;
        span {
          display: block;
          max-width: 3.5em;
          padding-left: inset();
        }
      }
    }
  }
  .page-footer {
    padding-bottom: $rhythm * 15;
    clear: both;
    &::before {
      content: '';
      display: block;
      @include prefix(12);
      border-left: 1px solid $line;
      padding-top: $rhythm * 15;
    }
    a {
      @include hover-color($gray);
    }
  }
}

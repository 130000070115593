.contact-content {
  background: $white;
  section {
    @include restricted-gallery($expand: false);
    width: 100%;
    overflow: hidden;
  }
  .content {
    position: relative;
    @include tablet-plus {
      @include font(10.5, 1.43);
    }
    .introduction {
      margin-bottom: $rhythm * 4;
      > * {
        max-width: 17.2em;
      }
    }
    a {
      text-transform: uppercase;
      @include hover-color($gray);
      @include tablet-plus {
        @include font(9.5, 1.43);
      }
    }
    h1 {
      @include font(40, .9, -.0095);
      margin-top: $rhythm * 4;
    }
    h2 {
      @include set-font('toolbar');
    }
    .logo {
      display: inline-block;
      width: 45%;
      img {
        width: 100%;
        height: auto;
      }
    }
  }
  .details {
    @include tablet-plus {
      @include font(9.5, 1.43);
    }
    a {
      @include set-font('toolbar');
      @include hover-color($gray);
    }
    h2 {
      @include set-font('toolbar');
    }
  }
  .page-footer {
    @include common-page-footer;
    @include phone {
      // @.page-footer
      .logo {
        width: 50%;
        display: inline-block;
        vertical-align: middle;
        text-align: center;
        left: -$rhythm * .5;
        position: relative;
        img {
          width: 80%;
        }
      }
      .brand {
        margin: 0;
        width: 50%;
        display: inline-block;
        vertical-align: middle;
      }
    }
  }
  @include phone {
    // @.contact-content
    .content {
      margin-bottom: $rhythm * 2;
      h1 {
        max-width: 6em;
      }
      .address {
        text-align: center;
      }
    }
    .details {
      text-align: center;
      padding-bottom: $rhythm;
      @include vertical-line(after);
    }
    .gallery {
      margin-bottom: $rhythm;
    }
  }
  @include tablet-plus {
    // @.contact-content
    section {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    .content {
      @include cols(6);
      border-right: 1px solid $line;
      order: 1;
      padding: inset();
      .logo {
        position: absolute;
        right: inset();
        bottom: inset();
      }
    }
    figure {
      @include cols(12);
      order: 2;
      border-right: 1px solid $line;
    }
    .details {
      @include cols(6);
      order: 3;
      padding: inset();
      @include height-minus-toolbars(100);
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      > *:last-child {
        margin: 0;
      }
    }
  }
  @include desktop-minus {
    // @.contact-content
    .content {
      padding-left: 0;
    }
    .details {
      padding-right: 0;
    }
  }
}

@mixin naked-button {
  border: 0;
  background: none;
  display: inline;
  padding: 0;
  border-radius: 0;
  @include font(15.4);
  @include hover-underline;
}

@mixin hover-color($color: $black, $transition: 250) {
  @if $transition != false {
    transition: color #{$transition}ms;
  }
  .no-touchevents &:hover {
    color: $color;
  }
}

@mixin hover-opacity($decimal: .8, $transition: 100) {
  @if $transition != false {
    transition: opacity #{$transition}ms;
  }
  .no-touchevents &:hover {
    opacity: $decimal;
  }
}

@mixin hover-underline($color: null, $reverse: false, $transition: 100) {
  @if $transition != false {
    transition: border-bottom-color #{$transition}ms;
  }
  @if $reverse != false {
    // border-bottom: .1em solid transparent;
    border-bottom: 1px solid transparent;
    .no-touchevents &:hover {
      border-bottom-color: $color;
    }
  } @else {
    @if $color == null {
      // border-bottom: .1em solid;
      border-bottom: 1px solid;
    } @else {
        // border-bottom: .1em solid $color;
        border-bottom: 1px solid $color;
    }
    .no-touchevents &:hover {
      border-bottom-color: transparent;
    }
  }
}

@mixin hover-state($touch-default: false) {
  /* Enable hover states on non-touch devices only. If $touch-default,
     default to the hovered state for touch devices.
     Requires Modernizer.touchevents */

  .no-touchevents &:hover {
    @content;
  }
  @if $touch-default {
    .touchevents & {
      @content;
    }
  }
}

@mixin background-color() {
  background: #eee; // override in html
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
}

@mixin font($font-size-please, $line-height: null,  $letter-spacing: null) {
  // input a px, get an em
  // if font-size is huge, adjust the line height
  $rem: ($font-size-please / $base-font-size) * 1rem;
  $px: $font-size-please + 0px;

  @if $px > 0 {
    font-size: $rem;
  }
  @if $line-height != null {
    line-height: $line-height;
  }
  @if $letter-spacing != null {
    letter-spacing: $letter-spacing + 0em;
  }
}

@mixin scalable-font($font-size-please,
                     $minimum: $minumum-font-size,
                     $line-height:$base-line-height,
                     $letter-spacing: null,
                     $maximum: null, $as-px: false) {
  // input a px, get a vw (based off $design-width)
  // e.g @include scalable-font(15, 15, 1.3, -.01);
  $ratio: $design-width / $font-size-please;
  $minimum-breakpoint: $minimum * $ratio;

  @media(max-width: $minimum-breakpoint - 1px) {
    // no-scale
    @if $as-px == true {
      // used for html
      font-size: $minimum + 0px;
    } @else {
      @include font($minimum);
    }
  }
  @media(min-width: $minimum-breakpoint + 0px) {
    // scale
    $scalable-font-size: #{(($font-size-please / $design-width) * 100) + 0vw};
    font-size: $scalable-font-size;
  }
  @if $maximum != null {
    // no-scale
    $maximum-breakpoint: $maximum * $ratio;
    @media(min-width: $maximum-breakpoint + 0px) {
      @if $as-px == true {
        // used for html
        font-size: $maximum + 0px;
      } @else {
        @include font($maximum);
      }
    }
  }
  @if $letter-spacing != null {
    letter-spacing: $letter-spacing + 0em;
  }
  line-height: $line-height;
}

@mixin scalable-property($prop, $size-please, $minimum: $minumum-font-size) {
  // input a px, get a vw (based off $design-width)
  $ratio: $design-width / $size-please;
  $minimum-breakpoint: $minimum * $ratio;
  @media(max-width: $minimum-breakpoint - 1px) {
    // no-scale
    $rem: ($minimum / $base-font-size) * 1rem;
    $px: $minimum + 0px;
    @if $px > 0 {
      #{$prop}: $rem;
    }
  }
  @media(min-width: $minimum-breakpoint + 0px) {
    // scale
    $scalable-size: #{(($size-please / $design-width) * 100) + 0vw};
    #{$prop}: $scalable-size;
  }
}

// standard clear fix for floats
@mixin clearfix {
  zoom: 1;
  &::after {
    visibility: hidden;
    content: '';
    display: table;
    clear: both;
    height: 0;
  }
}

@mixin fit-parent($position: absolute) {
  position: #{$position};
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

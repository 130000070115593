//
// All colours go here
$black: #000;
$almost-black: #4C4C4C;
$gray: #aaa;
$white: #fff;
$almost-white: #efefef;

$red: #e74242;
$green: #60942e;

$line: #979797;

// Fonts
$base-font-size: 11.5;
$base-line-height: 1.35;

// Vertical Rhythm
$rhythm-as-int: $base-line-height;
$rhythm: $rhythm-as-int + 0rem;

// Structural constants and helper mixins

$design-width: 1140; // reference width from the design
$design-gutter: 0;

// to add a max width for the site enable $fluid-max
$fluid-max: 1600px;
// $fluid-max: 2000px;

$tablet-max: 1100px;
$tablet-portrait: 930px;
$phone-max: 667px;
$phone-portrait: 375px;

$total-cols: 24;
$single-column: percentage(1 / $total-cols);
$default-gutter: percentage($design-gutter / $design-width);
$fallback-width: 1200px; // used in place of 100vw where vw not supported

$estmated-max-rooms: 5;

@function inset($multiple: 1, $negative: false) {
  @if ($negative == true) {
    @return -$rhythm * $multiple;
  } @else {
    @return $rhythm * $multiple;
  }
}

@mixin grid-container($prop:margin) {
    #{$prop}-left: inset();
    #{$prop}-right: inset();

  @if ($fluid-max) {
    @include fluid-plus {
      max-width: $fluid-max;
      margin: 0 auto;
    }
  }
}

@mixin gutters($gutter: $default-gutter, $parent-cols: $total-cols) {
  // Add left/right margin according to the grid gutter width.
  @if ($default-gutter != 0) {
    $factor: $total-cols / $parent-cols;
    margin-left: $gutter * $factor;
    margin-right: $gutter * $factor;
  }
}

@function columns($cols, $parent-cols: $total-cols, $gutter: $default-gutter) {
  $factor: $total-cols / $parent-cols;
  @return ($cols * $single-column - $gutter * 2) * $factor;
}

@mixin cols($cols, $parent-cols: $total-cols, $gutter: $default-gutter) {
  // Set width to the specified number of cols, with gutters.
  width: columns($cols, $parent-cols, $gutter);
  @include gutters($gutter, $parent-cols);
}

// @mixin ib-grid($args...) {
//   // wrapper for cols() which also makes the element an inline-block column
//   display: inline-block;
//   vertical-align: top;
//   @if length($args) > 0 {
//     @include cols($args...);
//   }
// }

@mixin prefix($cols: 0, $parent-cols: $total-cols, $gutter: $default-gutter,
              $prop: margin-left) {
  // Add left margin of the specified number of cols
  $factor: $total-cols / $parent-cols;
  #{$prop}: (($single-column * $cols) + $gutter) * $factor;
}

@mixin suffix($cols: 0, $parent-cols: $total-cols, $gutter: $default-gutter,
              $prop: margin-right) {
  // Add right margin of the specified number of cols
  $factor: $total-cols / $parent-cols;
  #{$prop}: (($single-column * $cols) + $gutter) * $factor;
}

@function grid-px($cols, $gutter: $default-gutter) {
  // Calculate the pixel width of a fluid grid element, at $design-width
  @return round(($cols / $total-cols - 2 * $gutter / 100%) * $design-width);
}

// media
@mixin fluid-plus {
  @if ($fluid-max) {
    $min: $fluid-max + 50; // allow a bit of a buffer before we trigger this
    @media screen and (min-width: $min) {
      @content;
    }
  }
}
@mixin desktop-minus {
  @if ($fluid-max) {
    $min: $phone-max; // don't worry about phone
    $max: $fluid-max + 49; // allow a bit of a buffer before we trigger this
    @media screen and (min-width: $min) and (max-width: $max) {
      @content;
    }
  }
}
@mixin desktop {
  $min: $tablet-max + 1;
  @if ($fluid-max) { $max: $fluid-max + 49; }
  @media screen and (min-width: $min) and (max-width: $max) {
    @content;
  }
}
@mixin desktop-plus {
  $min: $tablet-max + 1;
  @media screen and (min-width: $min) {
    @content;
  }
}
// mobile: encompassing tablet & phone
@mixin tablet-minus {
  $max: $tablet-max;
  @media screen and (max-width: $max) {
    @content;
  }
}
@mixin tablet {
  $min: $phone-max + 1;
  $max: $tablet-max;
  @media screen and (min-width: $min) and (max-width: $max) {
    @content;
  }
}
@mixin tablet-plus {
  $min: $phone-max + 1;
  @media screen and (min-width: $min) {
    @content;
  }
}
@mixin tablet-landscape-plus {
  $min: $tablet-portrait + 1;
  @media screen and (min-width: $min) {
    @content;
  }
}
@mixin tablet-landscape {
  $min: $tablet-portrait + 1;
  $max: $tablet-max;
  @media screen and (min-width: $min) and (max-width: $max) {
    @content;
  }
}
@mixin tablet-portrait {
  $min: $phone-max + 1;
  $max: $tablet-portrait;
  @media screen and (min-width: $min) and (max-width: $max) {
    @content;
  }
}
@mixin tablet-portrait-minus {
  $max: $tablet-portrait;
  @media screen and (max-width: $max) {
    @content;
  }
}
@mixin phone {
  $max: $phone-max;
  @media screen and (max-width: $max) {
    @content;
  }
}
@mixin phone-portrait {
  $max: $phone-portrait;
  @media screen and (max-width: $max) {
    @content;
  }
}

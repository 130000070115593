.accommodation-content {
  .introduction {
    position: relative;
    background: $white;
    z-index: 1000;
    @include phone {
      // @.introduction
      .inside {
        width: 90%;
        padding-top: inset();
        padding-bottom: inset();
      }
    }
    @include tablet-plus {
      // @.introduction
      .inside {
        @include prefix(12);
        border-left: 1px solid $line;
        padding-bottom: $rhythm * 2;
        padding-left: inset();
        padding-top: $rhythm * 2;
      }
      .column {
        padding-right: inset();
        margin-bottom: inset();
        > * {
          max-width: 21.5em;
        }
      }
    }
    @include tablet-landscape-plus {
      // @.introduction
      .column {
        width: 50%;
        display: inline-block;
        vertical-align: top;
      }
    }
  }
  .room-selector.primary {
    position: relative;
    background: $white;
    z-index: 1000;
    > h1 {
      display: block;
      text-align: center;
      @include set-font('toolbar');
      padding: $rhythm;
      @include vertical-line(before);
      @include vertical-line(after);
      margin: 0;
    }
    .room-options {
      .room {
        flex-grow: 1;
        width: 100%;
        padding: inset();
        // @include hover-color($gray);
        &:not(:last-child) {
          border-right: 1px solid $line;
        }
        .image {
          margin-bottom: inset(2);
        }
        h2 {
          @include font(15, 1, .06);
          text-transform: uppercase;
          text-align: center;
        }
        .summary {
          display: flex;
          justify-content: space-between;
          p {
            padding-right: $rhythm * .2;
            &:nth-child(1),
            &:nth-child(2) {
              width: 50%;
            }
          }
        }
      }
    }
    @include phone {
      // .room-selector
      @include vertical-line(after);
      .room-options {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        @include overhang-content;
        .inside {
          white-space: nowrap;
        }
        .room {
          display: inline-block;
          @include cols(18);
        }
      }
    }
    @include tablet-plus {
      // .room-selector
      .room-options {
        @include vertical-line(after);
        .inside {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          width: 100%;
        }
        .room {
          @include cols(6);
          .image {
            transition: filter 1s, opacity 1s;
            opacity: .9;
            filter: grayscale(100%);
          }
          .no-touchevents &:hover { // state
            .image {
              opacity: 1;
              filter: grayscale(0%);
            }
          }
        }
      }
    }
    @include tablet-portrait {
      // .room-selector
      @include overhang-content;
    }
    @include tablet-landscape-plus {
      // .room-selector
      .room-options {
        .room {
          padding: inset(2);
        }
      }
    }
    // DISABLED - this causes a misalignment with the following block
    // @include desktop-minus {
    //   // .room-selector
    //   @include overhang-content;
    // }
  }
  .pre-room-view {
    @include tablet-landscape-plus {
      // @.pre-room-view
      position: relative;
      z-index: 1000;
      background: $white;
      height: $rhythm * 10;
      margin-top: 1px;
      margin-bottom: 1px;
    }
    @include desktop-minus {
      @include overhang-content;
    }
  }
  .room-view {
    .rooms {
      .room {
        @include restricted-gallery;
        .description {
          margin-bottom: $rhythm * 3;
          h2 {
            @include font(40, 1.3, -.0095);
          }
          a {
            @include hover-underline;
          }
          .text {
            max-width: 90%;
            @include tablet-plus {
              @include font(10.5, 1.4);
            }
          }
        }
        .features {
          h2 {
            @include set-font('toolbar');
          }
          a {
            @include hover-underline;
          }
          .text {
            max-width: 80%;
            @include tablet-plus {
              @include font(9.5, 1.6);
            }
          }
        }
        .check-availibility {
          text-align: center;
          margin-top: $rhythm * 3;
          margin-bottom: $rhythm * 3;
          a {
            @include set-font('toolbar');
            @include hover-color($gray);
          }
        }
        .gallery {
          position: relative;
        }
      }
    }
    .room-selector.bonus {
      position: relative;
      z-index: 10;
      a {
        @include set-font('toolbar');
        @include standard-text-link;
        &:last-child {
          h2 {
            margin-bottom: 0;
          }
        }
      }
    }
    @include tablet-portrait-minus {
      // @.room-view
      .rooms {
        .room {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          > * {
            width: 100%;
          }
          > header {
            text-align: center;
            order: 1;
            h2 {
              margin-top: $toolbar-height + $rhythm;
              margin-bottom: 0;
              @include font(35, 1.3, -.0095);
            }
          }
          .gallery {
            order: 2;
            margin-bottom: $rhythm;
          }
          .slow {
            order: 3;
          }
          .fast {
            order: 4;
          }
        }
      }
      .room-selector.bonus {
        display: none;
      }
    }
    @include tablet-portrait {
      // @.room-view
      .rooms {
        .room {
          .slow,
          .fast {
            @include cols(12);
          }
          &:not(:first-child) {
            @include vertical-line(before);
            margin-top: $rhythm * 2;
          }
        }
      }
      .check-availibility {
        margin-right: -#{columns(12, 12)};
      }
    }
    @include tablet-landscape-plus {
      // @.room-view
      @include vertical-line(before);
      .rooms {
        position: relative;
        margin-left: -1px;
        margin-right: -1px;
        .room {
          // position: relative; // DISABLED everything is relative to .rooms
          display: flex;
          align-items: stretch;
          flex-direction: row;
          justify-content: space-between;
          // reverse the z-index
          // @for $i from 1 through $estmated-max-rooms {
          //   &:nth-child(#{$i}) {
          //     z-index: #{$estmated-max-rooms - $i};
          //   }
          // }
          aside.fast {
            position: fixed;
            top: 0;
            right: 0;
            order: 3;
            margin-top: $toolbar-height;
            @include cols(6);
            .inside {
              opacity: 0;
              // transition: 500ms opacity; // DISABLED this is set in restricted-gallery
              padding: inset();
              // margin-top: $toolbar-height;
            }
          }
          aside.slow {
            order: 1;
            min-height: 150vh;
            @include cols(6);
            .inside {
              opacity: 1;
              padding-top: $rhythm * 3;
              padding-bottom: $rhythm * 15;
              padding-left: inset();
              padding-right: inset();
            }
          }
          .gallery {
            order: 2;
            pointer-events: none;
            .inside {
              opacity: 0;
              transition: $gallery-opacity-timeout opacity;
            }
          }
          body.pause-current-room &:not(.force-current-room) {  // state-class
            aside.slow {
              .inside {
                opacity: 0;
              }
            }
          }
          body:not(.pause-current-room):not(.state-pseudo-room) &.state-current-room,
          &.force-current-room { // state-class
            aside.fast {
              .inside {
                opacity: 1;
              }
            }
            .gallery {
              pointer-events: all;
              .inside {
                opacity: 1;
              }
            }
          }
        }
      }
      .room-selector.bonus {
        @include cols(6);
        // margin-top: -$toolbar-height;
        pointer-events: none;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        nav {
          margin-top: $toolbar-height;
          @include height-minus-toolbars(100);
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          transition: $gallery-transition-timeout transform, 300ms opacity;
          transform: translateX(0%) translateZ(0);
          a {
            pointer-events: all;
          }
        }
        body.state-expanded-gallery-present & { // state class
                                                // some extras - tablet-plus (don't shift elements on phone)
          nav {
            transform: translateX(100%) translateZ(0);
          }
        }
        body.state-pseudo-room & {
          nav {
            opacity: 0;
          }
        }
      }
    }
    @include desktop-minus {
      // @.room-view
      .rooms {
        .room {
          aside.fast {
            padding-right: 0;
          }
          aside.slow {
            .inside {
              padding-left: 0;
            }
          }
        }
      }
    }
    @include fluid-plus {
      // @.room-view
      .room-selector.bonus {
        right: auto;
        left: calc(50% + #{($fluid-max / 4) + 0px});
        width: calc(25% - 50px});
      }
      .rooms {
        .room {
          aside.fast {
            right: auto;
            left: calc(50% + #{($fluid-max / 4) + 0px});
          }
        }
      }
    }
  }
  .page-footer {
    @include common-page-footer;
    @include tablet-landscape-plus {
      background: $white;
    }
  }
}

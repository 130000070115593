$base-requested-size: $base-font-size;
$base-minimum-size: $base-font-size;
$base-maximum-size: 14;

// this is the height of the font + padding

$toolar-font-size: 9.5;
$toolbar-ratio: $toolar-font-size / $base-font-size;
$toolbar-height-as-int: ($toolbar-ratio + ($rhythm-as-int * 2));
$toolbar-height: $toolbar-height-as-int + 0rem;
$collective-base-size: ($toolbar-height-as-int * 2) * $base-requested-size;
$collective-minimum-size: ($toolbar-height-as-int * 2) * $base-minimum-size;
$collective-maximum-size: ($toolbar-height-as-int * 2) * $base-maximum-size;

$gallery-transition-timeout: 1200ms;
$gallery-opacity-timeout: 1200ms;

@mixin set-font($type) {
  @if $type == 'body' {
    // NOTE all fonts scale from this
    @include scalable-font($base-requested-size, $base-minimum-size, $base-line-height, null,
                           $maximum: $base-maximum-size, $as-px: true);
  }
  @if $type == 'toolbar' {
    // NOTE this is used for the header/footer and a few other areas,
    //      height-minus-$base (below) depends on the same calculations
    text-transform: uppercase;
    @include phone {
      @include font($base-font-size, 1);
    }
    @include tablet-plus {
      @include font(9.5, 1);
    }
  }
}

@mixin standard-text-link() {
  @include hover-color($gray);
  &.current,
  &.child-current {
    color: $gray;
  }
}

@mixin overhang-content() {
  margin-left: inset($negative: true);
  margin-right: inset($negative: true);
}

@mixin vertical-line($position: after) {
  // we need these lines to overhang and touch the edges of the browser window
  // this puts the 'line' in the same position as the border would be
  position: relative;
  &::#{$position} {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    @if $position == before {
      top: -1px;
      border-top: 1px solid $line;
    } @else {
      bottom: -1px;
      border-bottom: 1px solid $line;
    }
    @include phone {
      @include overhang-content;
    }
    @include desktop-minus {
      @include overhang-content;
    }
  }
}

@mixin height-minus-toolbars($requested-height: 100, $extra: null) {
  // calculate a height of a container in vh minus the header and footer $bases
  $rem: ($toolbar-height-as-int * 2) + 0rem;
  @if $extra != null {
    height: calc(#{$requested-height + 0vh} - #{$rem} - #{$extra});
  } @else {
    height: calc(#{$requested-height + 0vh} - #{$rem});
  }
}

@mixin common-page-footer() {
  @include vertical-line(before);
  z-index: 50;
  text-align: right;
  .brand {
    display: inline-block;
    img {
      width: 100%;
      height: auto;
    }
  }
  @include phone {
    // @mixin common-page-footer
    .brand {
      margin-left: 50%;
      border-left: 1px solid $line;
      display: block;
      padding: $rhythm * 5 0 $rhythm * 5 $rhythm;
    }
  }
  @include tablet-plus {
    // @mixin common-page-footer
    padding: $rhythm * 5 0 $rhythm * 10;
    .brand {
      min-width: 220px;
      width: columns(4.5);
    }
  }
  @include fluid-plus {
    // @mixin common-page-footer
    padding-right: $rhythm;
  }
}






@mixin restricted-gallery ($expand: true) {
  // NOTE attached to the parent oof gallery
  .gallery {
    position: relative;
    // background: rgba(red, .2) !important;
    .inside {
      position: relative;
      overflow: hidden;
    }
    .gallery-items {
      transition: $gallery-transition-timeout transform;
      .gallery-item {
        position: relative;
        .image {
          @include fit-parent;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            padding: inset();
            padding-bottom: 0;
            height: auto;
            width: auto;
            max-width: 100%;
            max-height: 100%;
          }
        }
      }
    }
    nav {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-left: 3px; // prevent overlay of mask
      margin-right: 3px;
      @include tablet-plus {
        @include font(9.5, 1);
      }
      .next,
      .previous {
        font-family: arial, helvetica;
        padding: $rhythm;
        @include font(14, .678);
        opacity: 0;
        transition: 400ms opacity;
        i {
          font-style: normal;
        }
      }
      .counter {
        padding: $rhythm;
        white-space: nowrap;
        // @include font(9.5, 1)
        span {
          display: inline-block;
          vertical-align: middle;
          width: 1em;
          &.current {
            text-align: right;
          }
          &.line {
            position: relative;
            top: -.1em;
            text-align: center;
          }
        }
      }
      .expand-close {
        position: relative;
        @include cols(12);
          span {
            cursor: pointer;
            @include hover-color($gray);
            @include fit-parent;
            @include set-font('toolbar');
            padding: $rhythm;
            text-align: center;
            transition: 500ms opacity, 500ms color;
            transform: translateZ(0); // hardware accelerated
            &.close {
              opacity: 0;
              pointer-events: none;
            }
            &.expand {
              opacity: 1;
            }
          }
      }
    }
  }
  @include tablet-portrait-minus {
    // @mixin restricted-gallery
    .gallery {
      .poster {
        display: none;
      }
      .gallery-items {
        .gallery-item {
          @include height-minus-toolbars(70, #{$toolbar-height / 2});
          .image {
            padding-left: 0 !important;
            padding-right: 0 !important;
          }
          .image-background {
            margin-top: inset();
            height: auto;
            width: auto;
            max-width: 100%;
            max-height: 100%;
          }
        }
      }
      nav {
        .next {
          padding-right: 0;
        }
        .previous {
          padding-left: 0;
        }
      }
    }
  }
  @include tablet-landscape-plus {
    // @mixin restricted-gallery
    .shift-left,
    .shift-right {
      pointer-events: none;
      z-index: 3;
      .inside { // !important we neeeed to apply this to this element
                // because it gets fixed/unfixed with scroll balance
        transition: $gallery-transition-timeout transform, $gallery-opacity-timeout opacity;
        transform: translateX(0%) translateZ(0);
        pointer-events: all;
        clip: rect(0vh, 75vw, 100vh, 25vw);
      }
    }

    .gallery {
      margin-top: -$toolbar-height;
      .inside {
        margin-top: $toolbar-height;
        transform: translateZ(0);
      }
      .poster {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        transition: $gallery-transition-timeout transform;
        transform: translateX(0%) translateZ(0);
        cursor: pointer;
        @include height-minus-toolbars(100, #{$toolbar-height});
        left: columns(6);
        right: columns(6);
        .image {
          margin-left: inset();
          margin-right: inset();
          margin-top: inset();
          @include fit-parent;
        }
      }
      .gallery-items {
        .gallery-item {
          &:not(.single-item) {
            @include height-minus-toolbars(100, $toolbar-height);
            .image {
              .image-background {
                margin-bottom: 0;
                max-height: calc(100% - #{inset()});
              }
            }
          }
          &.single-item {
            @include height-minus-toolbars(100);
            .image {
              .image-background {
                max-height: calc(100% - #{inset() * 2});
              }
            }
          }
          .image {
            .image-background {
              margin: inset();
              height: auto;
              width: auto;
              max-width: calc(100% - #{inset() });
            }
          }
        }
      }
      &.is-expandable {
        position: absolute !important;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
        pointer-events: none;
        .gallery-items {
          // transform: translateX(#{columns(18)}) translateZ(0);
          transform: translateX(110%) translateZ(0);
        }
      }
    }

    .mask-left,
    .mask-right {
      // masking the gallery
      transition: $gallery-transition-timeout transform, $gallery-opacity-timeout opacity;
      transform: translateX(0%) translateZ(0);
      z-index: 2;
      position: absolute;
      top: 0;
      bottom: 0;
      @include cols(6);
      background: $white;
      // display: none;
      // &:before {
      //   content: '';
      //   position: absolute;
      //   top: 0;
      //   bottom: 1px; // show line below
      //   background: $white;
      //   width: inset();
      // }
    }
    .mask-left {
      left: 1px;
      border-right: 1px solid $line;
      // &::before {
      //   // right: inset();
      //   left: calc(100% + 2px);
      // }
    }
    .mask-right {
      right: 1px;
      border-left: 1px solid $line;
      // &::before {
      //   left: inset();
      //   // right: calc(100% + 1px);
      // }
    }

    body.state-expanded-gallery-present &.state-current-room  {  // 1. state class
                                                                 //    shift other items out of view and
                                                                 //    display gallery (poster) at 100%
        .gallery {
          pointer-events: all;
          nav {
            .next,
            .previous {
              opacity: 1;
              pointer-events: all;
            }
            .expand-close {
              .close {
                opacity: 1;
                pointer-events: all;
              }
              .expand {
                opacity: 0;
                pointer-events: none;
              }
            }
          }
        }
    }

    body.state-expanded-gallery-present & { // state class
                                            // some extras - tablet-plus (don't shift elements on phone)
       .shift-left .inside,
       .mask-left {
          transform: translateX(-100%) translateZ(0);
       }
       .shift-right .inside,
       .mask-right {
          transform: translateX(100%) translateZ(0);
       }
       .shift-left .inside,
       .shift-right .inside {
         opacity: 0 !important;
       }
       &.state-current-room.state-gallery-active {
         .poster {
           transform: translateX(-#{columns(18, 12)}) translateZ(0);
         }
         .gallery-items {
           transform: translateX(0%) translateZ(0);
         }
       }
    }
    body.state-unclip-gallery & {
      .gallery {
        .inside {
          clip: unset;
        }
      }
    }
  }
  // @include fluid-plus {
  //   body.state-expanded-gallery-present & { // state class
  //     .shift-left .inside,
  //     .shift-right .inside {
  //       opacity: 0 !important;
  //     }
  //   }
  // }
}
